/* feed back */
.feed-back {
    padding-block: var(--padding100);
    background-color: var(--sub-color);
}
.feed-back .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--white-color);
    text-transform: capitalize;
}
.feed-back .text .user {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--bg-color-3);
    margin-bottom: 30px;
}
.feed-back .text .user h2 {
    margin: 0;
}
.feed-back .text .user i {
    margin: 0;
    padding: 10px;
    font-size: 30px;
}
.feed-back .text h3 {
    max-width: 300px;
    margin: auto;
}
/* feed back */