/* Start Nav */

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}
.navbar-toggler {
    color: var(--sub-color);
}
.nav-link {
    transform: var(--transition5);
    color: var(--sub-color);
}
.nav-link.active {
    color: var(--main-color);
}
.nav-link:hover {
    color: var(--main-color);
}

/* End Nav */