/* contact */
.contact {
    padding-block: var(--padding100);
    background-color: var(--bg-color-2);
}
.contact .contact-contact {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}
.contact .contact-contact .image {
    position: relative;
    max-width: 400px;
    padding-inline: 10px;
}
.contact .contact-contact .image::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--main-color);
    height: 0;
    width: 5px;
    transition: var(--transition5);
    animation: linecontact 3s linear infinite;
}
.contact .contact-contact .image::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--main-color);
    height: 0;
    width: 5px;
    transition: var(--transition5);
    animation: linecontact 3s linear infinite;
}
@keyframes linecontact {
    0% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}
.contact .contact-contact .image img {
    width: 100%;
}
.contact .form {
    flex: 1;
}
.contact .social ul {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
}
.contact .social ul li a {
    font-size: 28px;
    color: var(--white-color);
}
.contact .form form input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid var(--bg-color-3);
    color: var(--white-color);
    font-size: 18px;
    text-align: center;
}
.contact .form form input::placeholder,
.contact .form form textarea::placeholder {
    color: var(--white-color);
    text-transform: capitalize;
    font-size: 18px;
}
.contact .form form textarea {
    text-align: center;
    border: none;
    border-bottom: 2px solid var(--bg-color-3);
    width: 100%;
    outline: none;
    padding: 10px;
    color: var(--white-color);
    font-size: 22px;
    background-color: transparent;
}
.contact .form form input[type=submit] {
    margin-top: 20px;
    background-color: var(--bg-color-3);
    transition: var(--transition3);
}
.contact .form form input[type=submit]:hover {
    box-shadow: 0 0 10px var(--white-color);
}
@media (max-width: 991px) {
    .contact .contact-contact {
        flex-direction: column-reverse;
    }
    .contact .contact-contact .form {
        width: 100%;
    }
}
/* contact */