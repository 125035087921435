/* About */
.about {
    padding-block: var(--padding100);
    background-color: var(--bg-color-2);
}
.about .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.about .container .image {
    flex: 1;
    position: relative;
    padding-inline: 10px;
}
.about .container .image img {
    width: 100%;
}
.about .container .image::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 0;
    width: 5px;
    background-color: var(--main-color);
    transition: var(--transition5);
    animation: line 3s linear infinite;
}
.about .container .image::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 5px;
    background-color: var(--main-color);
    transition: var(--transition5);
    animation: line 3s linear infinite;
}
@keyframes line {
    0% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}
.about .container img {
    width: 250px;
    display: block;
    margin: auto;
}
.about .container .text {
    flex: 1;
}
.about .container .text h2 {
    color: var(--white-color);
    font-size: 45px;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 30px;
}
.about .container .text p {
    color: var(--white-color);
    font-size: 20px;
    line-height: 1.6;
}
.about .container .text a {
    color: var(--white-color);
    font-size: 22px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--main-color);
    margin-top: 20px;
    transition: var(--transition3);
    text-transform: capitalize;
}
.about .container .text a:hover {
    box-shadow: 0 0 10px var(--white-color);
}
@media (max-width: 991px) {
    .about .container {
        flex-direction: column;
        text-align: center;
    }
    .about .container .text h2 {
        font-size: 30px;
    }
    .about .container .text p {
        font-size: 18px;
    }
}
/* About */